<template>
  <div class="outer-page">
    <!-- @page-change="pageChange"
      @page-size-change="pageSizeChange" -->
    <x-table
      :no-data-text="CA('cars_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
    ></x-table>
    <Modal v-model="carDetail.show" title="车辆详情" :width="600">
      <Form :label-width="100">
        <Row>
          <Col span="12">
            <FormItem label="车牌号：">
              <span>{{ carDetail.info.carPlate }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="车辆类型：">
              <span>{{ carDetail.info.carType }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="车辆品牌：">
              <span>{{ carDetail.info.carModel }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="车辆颜色：">
              <span>{{ carDetail.info.carPlateColor }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="联系人：">
              <span>{{ carDetail.info.carTelName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="联系电话：">
              <span>{{ carDetail.info.carTelPhone }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="年审日期：">
              <span>{{
                carDetail.info.carAnnualReview
                  ? carDetail.info.carAnnualReview.split(" ")[0]
                  : ""
              }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="交强险期限：">
              <span>{{
                carDetail.info.carClivtaExpire
                  ? carDetail.info.carClivtaExpire.split(" ")[0]
                  : ""
              }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="商业险期限：">
              <span>{{
                carDetail.info.carVehicleInsurance
                  ? carDetail.info.carVehicleInsurance.split(" ")[0]
                  : ""
              }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="所属公司：">
              <span>{{ carDetail.info.carCompanyName }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="状态：">
              <span>{{ carDetail.info.carStatus }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="图片：">
              <span style="display: inline-block; width: 60px; height: 60px">
                <img
                  style="width: 100%; height: 100%; cursor: pointer"
                  :src="carDetail.info.servicePath + carDetail.info.carImgUrl"
                  alt=""
                  @click="checkImgInfo"
                />
              </span>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
    <pic-view v-model="checkPic" :src="imgSrc"></pic-view>
  </div>
</template>

<script>
import picView from "@/components/picture_view";
export default {
  name: "",
  components: {
    picView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "图片",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              return (
                <div
                  style="width:60px;height:60px;padding:4px;cursor:pointer"
                  on-click={() => this.checkPicInfo(row)}
                >
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(row.servicePath, row.carImgUrl)}
                  />
                </div>
              );
            },
          },
          {
            title: "车牌号",
            align: "center",
            key: "carPlate",
          },
          {
            title: "车辆类型",
            align: "center",
            width: 200,
            key: "carType",
          },
          {
            title: "车辆颜色",
            align: "center",
            key: "carColor",
          },
          {
            title: "车辆品牌",
            align: "center",
            key: "carModel",
          },
          {
            title: "联系人",
            align: "center",
            key: "carTelName",
          },
          {
            title: "电话",
            align: "center",
            key: "carTelPhone",
          },
          {
            title: "所属公司",
            align: "center",
            key: "carCompanyName",
          },
          {
            title: "状态",
            align: "center",
            width: 150,
            key: "carStatus",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("cars_view") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      查看详情
                    </a>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          //   add: {
          //     addBtnName: "",
          //     ca: "",
          //   },
          width: 200,
          filterBox: [
            {
              conditionName: "车牌号",
              component: "input",
              field: "carPlate",
              defaultValue: "",
            },
          ],
        },
        // page: {
        //   pageNo: 1,
        //   pageSize: 10,
        //   total: 0,
        // },
      },
      search_data: {},
      //查看车辆图片
      checkPic: false,
      //车辆图片地址
      imgSrc: "",
      carDetail: {
        show: false,
        info: {},
      },
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    // pageChange(pageNo) {
    //   this.config.page.pageNo = pageNo;
    //   this.getList();
    // },
    // pageSizeChange(pageSize) {
    //   this.page.pageSize = pageSize
    //   this.getList()
    // },

    getList() {
      if (!this.CA("cars_check")) return;
      this.table.loading = true;
      this.$post(this.$api.SPORT_STATUS.CARS, {
        // pageNo: this.config.page.pageNo,
        // pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res;
          // this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    //查看图片
    checkPicInfo(row) {
      this.checkPic = true;
      this.imgSrc = row.servicePath + row.carImgUrl;
    },
    checkImgInfo() {
      this.checkPic = true;
      this.imgSrc = this.carDetail.info.servicePath + this.carDetail.info.carImgUrl;
    },
    checkInfo(row) {
      this.carDetail = {
        show: true,
        info: row,
      };
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>